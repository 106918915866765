export const ADD_LIST_RALLY="ADD_LIST_RALLY";
export const ADD_RESULT_BY_RALLY="ADD_RESULT_BY_RALLY";
export const GET_RESULT_BY_RALLY="GET_RESULT_BY_RALLY";
export const ADD_SPECIAL_BY_RALLY="ADD_SPECIAL_BY_RALLY";
export const ADD_SPECIAL_BY_RALLY_RECAP="ADD_SPECIAL_BY_RALLY_RECAP";
export const GET_RESULT_BY_SPECIAL="GET_RESULT_BY_SPECIAL";

export const DELETE_LIST_RALLY="DELETE_LIST_RALLY";
export const DELETE_RESULT_BY_RALLY="DELETE_RESULT_BY_RALLY";
export const DELETE_SPECIAL_BY_RALLY="DELETE_SPECIAL_BY_RALLY";
export const DELETE_SPECIAL_BY_RALLY_RECAP="DELETE_SPECIAL_BY_RALLY_RECAP";
export const DELETE_RESULT_BY_SPECIAL="DELETE_RESULT_BY_SPECIAL";

export const ADD_LIST_CLASSE="ADD_LIST_CLASSE";
export const DELETE_LIST_CLASSE="DELETE_LIST_CLASSE";