import React from "react";
import {StyleSheet, Text, View, TouchableOpacity, Image} from "react-native";
import { Icon } from 'react-native-elements';


const Rallye = ({navigation,data}) => {
    

    const {idRallye, lieu,nomRallye, dateDebut, equipage,saison} = data;

    if(saison!=null){
    return(
        <View style={styles.rallyeContainer}>
        
            <View style={styles.titleMainContainer}>
                <View style={styles.titleContainer}>
                    <Image source={require("../../../../assets/images/flag.png")} style={styles.titleIcon}/>
                    <Text style={styles.title}>{lieu}</Text>
                </View>
                <View style={styles.subTitleContainer}>
                    <Text style={styles.subTitle}>{nomRallye}</Text>
                </View>
            </View>
            
            <View style={styles.bodyContainer}>
                <Text style={styles.bodyText}>
                    <Text style={{fontWeight : "bold"}}>Date :</Text> {dateDebut}
                </Text>
                {/*<Text style={styles.bodyText}>
                    <Text style={{fontWeight : "bold"}}>Champion :</Text> {equipage}
                </Text>*/}
            </View>
            <TouchableOpacity style={styles.resultsButton} onPress={()=>{
                navigation.navigate("ResultInfo", {rallyeId : idRallye,nomRallye : nomRallye});
            }}>
                <Icon name='pending-actions' color='#000' type="material" size={30} style={styles.titleIcon}/>
                <Text style={styles.resultsButtonText}>RESULTATS</Text>
            </TouchableOpacity>
        </View>
    );
        }else{
            return null;
        }
}

const styles = StyleSheet.create({
    rallyeContainer: {
        height: 195,
        marginTop: 30,
        marginLeft: 10,
        marginRight: 10,
        borderColor: "#ededed",
        borderStyle: "solid",
        borderWidth: 4
    },
    titleMainContainer:{
        flexDirection:"column",
        borderBottomColor: "#ededed",
        borderStyle: "solid",
        borderBottomWidth: 4,
    },
    titleContainer: {
        height: 42,
        backgroundColor : "#fff",
        flexDirection: "row",
        alignItems: "center",
        marginTop:10
    },
    subTitleContainer:{
        flexDirection:"column",
        height: 32,
    },
    title: {
        fontSize: 25,
        marginLeft: 15,
      
    },
    subTitle: {
        fontSize: 16,
        marginLeft: 55,
      
    },
    titleIcon : {
        height: 30,
        width: 30,
        marginLeft: 10
    },
    bodyContainer: {
        height: 50,
        backgroundColor: "#f3f3f3",
        
        justifyContent : "space-around",
        borderBottomColor: "#ededed",
        borderStyle: "solid",
        borderBottomWidth: 4,
        alignItems: "center"
    },
    bodyText: {
        fontSize : 15
    },
    resultsButton : {
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f3f3f3",
        flexDirection: "row",
    },
    resultsButtonText : {
        fontSize: 18,
        marginLeft: 10
    }
});
export default Rallye;