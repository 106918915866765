import axios from "axios";
import {addListRallyAction,addSpecialByRallyActionRecap,addResultByRallyAction,addSpecialByRallyAction,addResultBySpecialAction,addListClasseAction} from "../redux/actions";

const ENDPOINT_BASE = "https://rallygasy.itu-labs.com/rallygasy-webservice/";
export const getListRally = async (dispatch) => {
    try {
      
      const response = await axios.get(
        ENDPOINT_BASE + "rallys",
        {}
      );
      //console.log("reponse " + JSON.stringify(response.data.data.length));
      await getListClasse(dispatch);
        for(var i=0;i<response.data.data.length;i++){
          await getResultByRally(response.data.data[i].idRallye,dispatch);
          await  getSpecialByRally(response.data.data[i].idRallye,dispatch);
          await  getSpecialByRallyRecap(response.data.data[i].idRallye,dispatch);
        }
        await dispatch(addListRallyAction(response.data.data));
        console.log(
          ENDPOINT_BASE + "rallys" 
        );
        return false;
    } catch (e) {
      console.log(e);
    } 
};
export const getResultByRally = async (idRally,dispatch) => {
  try {
    
    const response = await axios.get(
      ENDPOINT_BASE + "global-results/"+idRally,
      {}
    );
    
      const result={
        idRally:idRally,
        data:response.data.data
    };
    console.log(result);
      await dispatch(addResultByRallyAction(result));
      // console.log(
      //   ENDPOINT_BASE + "global-results/"+idRally 
      // );
  } catch (e) {
    console.log(e);
  }
};
export const getSpecialByRally = async (idRally,dispatch) => {
  try {
    
    const response = await axios.get(
      ENDPOINT_BASE + "specials/"+idRally,
      {}
    );
    
      const result={
        idRally:idRally,
        data:response.data.data
      };
      console.log(result);
      await dispatch(addSpecialByRallyAction(result));
      console.log(
        ENDPOINT_BASE + "specials/"+idRally 
      );
  } catch (e) {
    console.log(e);
  }
};
export const getResultBySpecial = async (idSpecial,dispatch) => {
  try {
    
    const response = await axios.get(
      ENDPOINT_BASE + "special-results/"+idSpecial,
      {}
    );
    
      const result={
        idSpecial:idSpecial,
        data:response.data.data
      };
      console.log(result);
      await dispatch(addResultBySpecialAction(result));
      console.log(
        ENDPOINT_BASE + "special-results/"+idSpecial 
      );
  } catch (e) {
    console.log(e);
  }
};
export const getListClasse = async (dispatch) => {
  try {
    
    const response = await axios.get(
      ENDPOINT_BASE + "classes",
      {}
    );
    let data=[];
    data.push({
      value:0,
        label:"Tous"
    });
    for(var i=0;i<response.data.data.length;i++){
      response.data.data[i].idClasse=parseInt(response.data.data[i].idClasse);
      data.push({
        value:response.data.data[i].idClasse,
        label:response.data.data[i].nomClasse
      });
    }
    
   //console.log("reponse " + JSON.stringify(data));
   await dispatch(addListClasseAction(data));
  //  console.log(
  //   ENDPOINT_BASE + "classes" 
  // );
  } catch (e) {
    console.log(e);
  }
};

export const getSpecialByRallyRecap = async (idRally,dispatch) => {
  try {
    
    const response = await axios.get(
      ENDPOINT_BASE + "specials-recap/"+idRally,
      {}
    );
    
      const result={
        idRally:idRally,
        data:response.data.data
      };
      //console.log(result);
      await dispatch(addSpecialByRallyActionRecap(result));
      // console.log(
      //   ENDPOINT_BASE + "specials-recap/"+idRally 
      // );
  } catch (e) {
    console.log(e);
  }
};