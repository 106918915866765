import React from "react";
import {StyleSheet, View, Text,Image} from "react-native";
import Localisation from "../localisation/Localisation";
import { Icon } from 'react-native-elements';
const LineResult = ({position,carNumber,crew,classe,time,vitesse,diffLead,diffPrev,lineColor})=>{
    const results = Localisation.data.results;
    return(
        <View >
                
                <View style={[styles.rowContainer,{backgroundColor:lineColor}]}>
                
                    <Text style={[styles.rowText,{width:15,marginLeft:5}]}>{position}</Text>
                    <Text style={[styles.rowText,{width:30,marginLeft:0,textAlign:"right"}]}>#{carNumber}</Text>
                    <Text style={[styles.rowText,{width:90,marginLeft:5}]}>{crew}</Text>
                    <Text style={[styles.rowText,{width:33,marginLeft:5}]}>{classe}</Text>
                    <Text style={[styles.rowText,{width:64,marginLeft:3}]}>{time}</Text>
                <Text style={[styles.rowText, { width: 35, marginLeft: -5 }]}>{vitesse}</Text>
                    <Text style={[styles.rowText,styles.diff,{width:50,marginLeft:0,fontSize: 9}]}>{diffLead}</Text>
                    <Text style={[styles.rowText,styles.diff,{width:50,marginLeft:5, fontSize: 9}]}>{diffPrev}</Text>
                </View>
        </View>
    );
}
const styles = StyleSheet.create({
    homeContainer: {
        backgroundColor: "#fff"
    },
    rowText:{
        fontSize: 10,
        
        marginLeft:5,
        width:20
    },
    rowContainer:{
        backgroundColor: "#fff",
        height:35,
        flexDirection: "row",
        alignItems:"center"
    },
    titleIcon : {
        height: 25,
        width: 25,
        marginLeft:15
    },
    crewContainer:{
        width:140,
        marginLeft:20,
        flexDirection: "row",
        
    },
    crewText:{
        
    },
    diff : {
        fontSize: 10,
    }
});
export default LineResult;