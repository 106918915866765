import React from "react";
import { StyleSheet, Text, View,Image,SafeAreaView } from 'react-native';
import {
    DrawerContentScrollView,
    DrawerItemList,
    DrawerItem,
  } from '@react-navigation/drawer';
const LogoPage = (props) => {
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={styles.iconContainer}>
                <Image style={styles.iconStyle} source={require("../../assets/images/logo-rallye-gasy.png")} />
            </View>
            <DrawerContentScrollView {...props}>
                <DrawerItemList {...props} />
            </DrawerContentScrollView>
        </SafeAreaView>
    );
  }
const styles = StyleSheet.create({
    iconStyle: {
        width: 250,
        height: 60,
        marginHorizontal: 10,
        marginVertical:20
      },
      iconContainer: {
        width: 280,
        height: 100,
        
      }
});
export default LogoPage;