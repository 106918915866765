import {ADD_LIST_RALLY,ADD_RESULT_BY_RALLY,GET_RESULT_BY_RALLY,ADD_SPECIAL_BY_RALLY,ADD_SPECIAL_BY_RALLY_RECAP,GET_RESULT_BY_SPECIAL,DELETE_RESULT_BY_RALLY,DELETE_SPECIAL_BY_RALLY,DELETE_SPECIAL_BY_RALLY_RECAP,DELETE_RESULT_BY_SPECIAL,DELETE_LIST_RALLY,ADD_LIST_CLASSE,DELETE_LIST_CLASSE} from "./actionsType";
export const addListRallyAction = data => ({
    type: ADD_LIST_RALLY,
    payload: {
      data
    }
});
export const addResultByRallyAction = data => ({
    type: ADD_RESULT_BY_RALLY,
    payload: {
        data
    }
});
export const getResultByRallyAction = idRally => ({
    type: GET_RESULT_BY_RALLY,
    payload: {
        idRally
    }
});
export const addSpecialByRallyAction = data => ({
    type: ADD_SPECIAL_BY_RALLY,
    payload: {
        data
    }
});
export const addSpecialByRallyActionRecap = data => ({
    type: ADD_SPECIAL_BY_RALLY_RECAP,
    payload: {
        data
    }
});
export const addResultBySpecialAction = data => ({
    type: GET_RESULT_BY_SPECIAL,
    payload: {
        data
    }
});
export const addListClasseAction = data => ({
    type: ADD_LIST_CLASSE,
    payload: {
      data
    }
});
export const deleteListRallyAction = data => ({
    type: DELETE_LIST_RALLY,
    payload: {
      data
    }
});
export const deleteSpecialByRallyAction = data => ({
    type: DELETE_SPECIAL_BY_RALLY,
    payload: {
        data
    }
});
export const deleteSpecialByRallyActionRecap = data => ({
    type: DELETE_SPECIAL_BY_RALLY_RECAP,
    payload: {
        data
    }
});
export const deleteResultByRallyAction = data => ({
    type: DELETE_RESULT_BY_RALLY,
    payload: {
        data
    }
});
export const deleteResultBySpecialAction = data => ({
    type: DELETE_RESULT_BY_SPECIAL,
    payload: {
        data
    }
});
export const deleteListClasseAction = data => ({
    type: DELETE_LIST_CLASSE,
    payload: {
      data
    }
});