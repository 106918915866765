import React,{useState} from "react";
import {StyleSheet, Text, View, Image,TouchableOpacity,ActivityIndicator } from "react-native";
import { Icon } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import{getListRally} from "../../../api/RallyApi";
import { useDispatch } from "react-redux";
import {deleteSpecialByRallyAction,deleteResultByRallyAction,deleteResultBySpecialAction,deleteListRallyAction,deleteSpecialByRallyActionRecap} from "../../../redux/actions";

const Header = ()=>{
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const refreshData=async()=>{
        setIsLoading(true)
        dispatch(deleteListRallyAction([]));
        dispatch(deleteSpecialByRallyAction([]));
        dispatch(deleteResultByRallyAction([]));
        dispatch(deleteResultBySpecialAction([]));
        dispatch(deleteSpecialByRallyActionRecap([]));
        setIsLoading(await getListRally(dispatch));
        setIsLoading(false)
        navigation.navigate('Home', { isUpdate: true });
    }
    return(
        <View>
            <View style={styles.headerContainer}>
                <TouchableOpacity onPress={()=>navigation.toggleDrawer()}>
                    <Icon name='menu' color='#fff' type="material" style={[styles.headerIcon, styles.homeIcon]} size={35}/>
                </TouchableOpacity>
                <Image source={require("../../../../assets/images/logo-rallye-gasy-negatif.png")} style={styles.headerLogo}/>
                <TouchableOpacity onPress={()=> refreshData()}>
                    <Icon name='refresh' color='#fff' type="material" style={[styles.headerIcon, styles.userIcon]} size={35}/>
                </TouchableOpacity >
            </View>
            <View style={styles.buttonContainer}>
                <View style={styles.button}>
                {isLoading  && <ActivityIndicator size="large" color="#E33813"/>}
                </View>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    headerContainer: {
        height: 70,
        backgroundColor: "#000",
        color : "#fff",
        flexDirection: "row",
        
        paddingTop: 14,
    },
    headerLogo : {
        width: 200,
        height: 50,
        marginLeft:20
    },
    headerIcon : {
        marginTop: 5,
        marginLeft:20
    },
    homeIcon : {
        
    },
    userIcon : {
        
    }
});

export default Header;