import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "../home/Home";
import ResultInfo from "../result/ResultInfo";
import { StyleSheet, Text, View } from 'react-native';
const Stack = createStackNavigator();

const PageNavigator = () => {
    return (
        <Stack.Navigator options={{headerShown: false}}>
        <Stack.Screen name="Home" component={Home} options={{headerShown: false}}  />
        <Stack.Screen name="ResultInfo" component={ResultInfo} options={{headerTitle: ()=> <View style={styles.titleContainer}>
            <Text style={styles.title}>Résultat</Text>
        </View>}} />
      </Stack.Navigator>
    );
  }
const styles = StyleSheet.create({

    titleContainer: {
        flexDirection: "row",
        alignItems:"center",
        height:50, 
    },
    title: {
        fontSize: 25,
        
        fontWeight: "bold",
        width:"auto"
    }
});
export default PageNavigator;