import React,{ useState, useEffect } from "react";
import {Dimensions ,StyleSheet, View, ScrollView, Text,Image,FlatList,TouchableOpacity} from "react-native";
import { Icon } from 'react-native-elements';
import Localisation from "../_shared/localisation/Localisation";
import LineReult from "../_shared/LineData/LineResult";
import { useSelector } from "react-redux";
import { addResultByRallySelector,specialByRallySelector,specialByRallySelectorRecap,addResultBySpecialSelector,addListClasseSelector } from "../../redux/selectors";
import Special from "../_shared/LineData/Special";
import { useDispatch } from "react-redux";
import DropDownPicker from "react-native-dropdown-picker";
const ResultInfo = ({route, navigation})=>{
    const specialLineColor="#CECEF6";
    const {rallyeId} = route.params;
    const {nomRallye} = route.params;
    const [resultTitle, setResultTitle] = useState("");
    const [results, setResults] = useState([]);
    const [listSpecialByRally, setListSpecialByRally] = useState([]);
    const [listSpecialByRallyRecap, setListSpecialByRallyRecap] = useState([]);
    const [windowHeight, setWindowHeight] = useState();
    const [cumul, setCumul] = useState(false);
    const liste=useSelector(addResultByRallySelector);
    const listSpecial=useSelector(specialByRallySelector);
    const listSpecialRecap=useSelector(specialByRallySelectorRecap);
    const resultBySpecial=useSelector(addResultBySpecialSelector);
    
    
    
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(useSelector(addListClasseSelector));
      const [value, setValue] = useState(items[0].value);
     // console.log("-----------"+JSON.stringify(rallyeId));
      const [bySpecial, setBySpecial] = useState(false);
      const [idSpecial, setIdSpecial] = useState("0");
      const [selectedSpecial, setSelectedSpecial] = useState("0");
    const getResultByClasse=()=>{
        if(bySpecial){
            getResultByIdSpecial(idSpecial);
        }else{
            getResultByIdRally();
        }
    }
    const getResultByIdRally=()=>{
        let result = [];
        let pre_result = [];
       // console.log(JSON.stringify(liste));
        for(var i=0;i<liste.length;i++){
            if (liste[i].idRally===rallyeId) {
               // console.log(JSON.stringify(liste[i].data))
                pre_result = liste[i].data;
                for(var s=0;s<pre_result.length;s++){
                    if(pre_result[s].idClasse===""+value){
                        result.push(pre_result[s]);
                    }else if(value===0){
                        result.push(pre_result[s]);
                    }
                }
                break;
            }
        }
        var lead="";
        var prev="";
        var color="#fff";
        var specialMax;
        for(var i=0;i<result.length;i++){
            if(i==0){
                lead=result[0].temps;
                prev=result[0].temps;
                specialMax=parseInt(result[0].nombreSpeciale);
            }
            if(specialMax>parseInt(result[i].nombreSpeciale)){
                specialMax=parseInt(result[i].nombreSpeciale);
                lead=result[i].temps;
                prev=result[i].temps;
                color=specialLineColor;
            }
            //console.log(JSON.stringify(result[i].temps));
            result[i].diffFirst=dateDiff(lead,result[i].temps);
            result[i].diffPrev=dateDiff(prev,result[i].temps);
            //console.log(JSON.stringify(result[i]));
            result[i].color=color;
            prev=result[i].temps;

        }
        //console.log("-----------"+JSON.stringify(result.length));
        setResultTitle("Recap");
        setResults(result);
        setBySpecial(false);
        setSelectedSpecial("0");
    };
    const getSpecialByIdRally=()=>{
        let result = [];
        let listS = [];
        //console.log(JSON.stringify(listSpecial));
        for (var i = 0; i < listSpecial.length; i++){
            //console.log(listSpecial);
            if (listSpecial[i].idRally===rallyeId) {
                listS = listSpecial[i].data;
                for(var s=0;s<listS.length;s++){
                    if(listS[s].result.length>0){
                        result.push(listS[s]);
                    }
                }
                break;
            }
        }
        //console.log("+++++++++++"+JSON.stringify(result.length));
        return result;
    };

    const getSpecialByIdRallyRecap=()=>{
        let result = [];
        let listS = [];
        //console.log(JSON.stringify(listSpecialRecap));
        for (var i = 0; i < listSpecialRecap.length; i++){
            //console.log(listSpecialRecap);
            if (listSpecialRecap[i].idRally===rallyeId) {
                listS = listSpecialRecap[i].data;
                for(var s=0;s<listS.length;s++){
                    if(listS[s].result.length>0){
                        result.push(listS[s]);
                    }
                }
                break;
            }
        }
        //console.log("+++++++++++"+JSON.stringify(result.length));
        return result;
    };


    const getResultByIdSpecial=(idSpecial)=>{
        let resultSpecial = [];
        
        for(var i=0;i<listSpecial.length;i++){
            if (listSpecial[i].idRally===rallyeId) {
                resultSpecial = listSpecial[i].data;
                break;
            }
        }
        var result = [];
        let pre_result = [];
        //console.log("taille "+resultSpecial.length);
        for(var i=0;i<resultSpecial.length;i++){
            if (resultSpecial[i].idSpeciale===idSpecial ) {
                pre_result = resultSpecial[i].result;
                setResultTitle("ES"+resultSpecial[i].numero+"    "+resultSpecial[i].distance+" km");
                for(var s=0;s<pre_result.length;s++){
                    if(pre_result[s].idClasse===""+value){
                        result.push(pre_result[s]);
                    }else if(value===0){
                        result.push(pre_result[s]);
                    }
                }
                break;
            }
        }
        var lead="";
        var prev="";
        var color="#fff";
        
        for(var i=0;i<result.length;i++){
            if(i==0){
                lead=result[0].tempsPenalise;
                prev=result[0].tempsPenalise;
               
            }
            
            result[i].diffFirst=dateDiff(lead,result[i].tempsPenalise);
            result[i].diffPrev=dateDiff(prev,result[i].tempsPenalise);
            result[i].color=color;
            prev=result[i].tempsPenalise;

        }
        setResults(result);
        setBySpecial(true);
        setIdSpecial(idSpecial);

    };
    const getResultByIdSpecialRecap=(idSpecial)=>{
        let resultSpecial = []; 
        for(var i=0;i<listSpecialRecap.length;i++){
            if (listSpecialRecap[i].idRally===rallyeId) {
                resultSpecial = listSpecialRecap[i].data;
                break;
            }
        }
        var result = [];
        let pre_result = [];
        //console.log("taille "+resultSpecial.length);
        for(var i=0;i<resultSpecial.length;i++){
            if (resultSpecial[i].idSpeciale===idSpecial ) {
                pre_result = resultSpecial[i].result;
                setResultTitle("ES"+resultSpecial[i].numero+"    "+resultSpecial[i].distance+" km    Cumul");
                for(var s=0;s<pre_result.length;s++){
                    if(pre_result[s].idClasse===""+value){
                        result.push(pre_result[s]);
                    }else if(value===0){
                        result.push(pre_result[s]);
                    }
                }
                break;
            }
        }
        //console.log(JSON.stringify(result));
        var lead="";
        var prev="";
        var color="#fff";
        
        for(var i=0;i<result.length;i++){
            if(i==0){
                lead=result[0].tempsPenalise;
                prev=result[0].tempsPenalise;
               
            }
            
            //console.log(JSON.stringify(result[i].tempsPenalise));
            result[i].diffFirst=dateDiff(lead,result[i].tempsPenalise);
            result[i].diffPrev=dateDiff(prev,result[i].tempsPenalise);
            result[i].color=color;
            //console.log(JSON.stringify(result[i]));
            prev=result[i].tempsPenalise;

        }
        setResults(result);
        setBySpecial(true);
        setIdSpecial(idSpecial);

    };
    function dateDiff(time1,time2) {
        var stringDate1='2020-01-01T'+time1+'00Z';
        var stringDate2='2020-01-01T'+time2+'00Z';
        
        //console.log(stringDate1);
        let date1 = new Date(stringDate1);
        let date2 = new Date(stringDate2);
        
        //console.log(date1);
        //console.log(date2);
        
        var datediff=new Date((date2.getTime()-date1.getTime())).toISOString().substr(11, 10);
        
        return datediff;
      }
      //console.log("++++++++++++");
    useEffect(() => {
        getResultByIdRally();
        setListSpecialByRally(getSpecialByIdRally());
        setListSpecialByRallyRecap(getSpecialByIdRallyRecap());
        let wHeight = Dimensions.get('window').height;
        //console.log(wHeight);
       
            setWindowHeight(wHeight-420);
        
       
        //console.log("-----------");
    }, []);
    
      const renderItem = ({ item }) => (
        <Special item={item} getResultMethode={getResultByIdSpecial} getResultMethodeRecap={getResultByIdSpecialRecap}  selectedSpecial={selectedSpecial} setSelectedSpecial={setSelectedSpecial} cumul={cumul} setCumul={setCumul}/>
    );
    
    return(
        
            <View style={styles.resultContainer}>
                  <View style={styles.titleContainer}>
                    <Icon name='pending-actions' color='#000' type="material" size={25} style={styles.titleIcon}/>
                <Text style={styles.title}>{nomRallye}</Text>
                </View>
               
                <View style={styles.resultTitleContainer}>
                    <Text style={styles.resultTitle}>{resultTitle}</Text>
                </View>
                
                <View style={styles.stageContainer}>
                    <TouchableOpacity onPress={()=>{getResultByIdRally()}}>
                        <View style={[styles.stage,{backgroundColor: selectedSpecial === "0" ? '#E33813' : 'black'}]}>
                            <Text style={styles.stageTitle}>Recap</Text>
                        </View>
                    </TouchableOpacity>
                    <FlatList
                        data={listSpecialByRally}
                        renderItem={renderItem}
                        keyExtractor={item => item.idSpeciale}
                        horizontal={true}
                    />
                    
                </View>
                <View style={styles.containerDropdown}>
                    <DropDownPicker
                    open={open}
                    value={value}
                    items={items}
                    setOpen={setOpen}
                    setValue={setValue}
                    setItems={setItems}
                    style={styles.dropdown}
                    onChangeValue={() => getResultByClasse()}
                    
                    />
                </View>
                
                <ScrollView horizontal={true}>
                    <View>
                        <View style={styles.columnContainer}>
                            <Text style={[styles.columnText,{width:15,marginLeft:5}]}></Text>
                            <Text style={[styles.columnText,{width:25,marginLeft:5,textAlign:"right"}]}>NUM</Text>
                            <Text style={[styles.columnText,{width:50,marginLeft:8}]}>EQUIPAGE</Text>
                            <Text style={[styles.columnText,{width:40,marginLeft:38}]}>CLASSE</Text>
                            <Text style={[styles.columnText,{width:40,marginLeft:5}]}>TEMPS</Text>
                        <Text style={[styles.columnText, { width: 45, marginLeft: 7}]}>V.MOY
                            <Text style={[{ fontSize: 6 }]}> kmh</Text>
                        </Text>
                            <Text style={[styles.columnText,{width:45,marginLeft:5}]}>DIFF 1er</Text>
                            <Text style={[styles.columnText,{width:45,marginLeft:5}]}>DIFF prec</Text>
                        </View>
                        
                        <ScrollView scrollEnabled={true} style={[styles.rowContainer,{height:windowHeight}]}>
                            {
                            results.map((data)=>(
                                    <LineReult key={data.idEquipage+data.tempsTotal} position={data.rang} carNumber={data.numero} crew={data.equipage} classe={data.nomClasse} time={data.temps} vitesse={data.vitesse} diffLead={data.diffFirst} diffPrev={data.diffPrev} lineColor={data.color}/>
                                ))
                            }
                        </ScrollView>
                    </View>
                </ScrollView>
                
            </View>
        
    );
}
const styles = StyleSheet.create({
    resultContainer: {
        backgroundColor: "#fff",
       marginBottom:100
    },
    stage:{
        width:50,
        height:30,
        backgroundColor:"#101418",
        alignItems:"center",
        justifyContent:"center",
        marginRight:5
    },
    stageTitle:{
        color:"white"
    },
    stageContainer:{
        flexDirection: "row",
        marginLeft:20,
        marginTop:15,
        
    },
    titleContainer: {
        flexDirection: "row",
        alignItems:"center",
        height:50,
       marginTop:15,
       
        
    },
    resultTitleContainer: {
        flexDirection: "row",
        alignItems:"center",
        height:30,
      
    },
    title: {
        fontSize: 20,
        marginLeft: 15,
        fontWeight: "bold",
        width:"auto"
    },
    resultTitle: {
        fontSize: 20,
        marginLeft: 20,
        fontWeight: "bold",
        width:"auto",
        color:"#E33813"
    },
    titleIcon : {
        height: 25,
        width: 25,
        marginLeft:15
    },
    columnContainer:{
        width:"100%",
        flexDirection: "row",
        backgroundColor:"#F7F6F6",
        height:50,
        alignItems:"center",
        marginTop:20
    },
    rowContainer:{
        width:"100%",
        flexDirection: "column"
    },
    columnText:{
        fontSize: 9,
        fontWeight: "bold",
    },
  containerDropdown: {
    width: "90%",
    marginTop:20,
    paddingLeft: 7,
    zIndex: 1000
  },
  dropdown: {
    width: "75%",
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 4,
    paddingLeft: 7
  }
});
export default ResultInfo;