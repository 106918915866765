import {ADD_LIST_RALLY,ADD_RESULT_BY_RALLY,GET_RESULT_BY_RALLY,ADD_SPECIAL_BY_RALLY,ADD_SPECIAL_BY_RALLY_RECAP,GET_RESULT_BY_SPECIAL,DELETE_RESULT_BY_RALLY,DELETE_SPECIAL_BY_RALLY,DELETE_SPECIAL_BY_RALLY_RECAP,DELETE_RESULT_BY_SPECIAL,DELETE_LIST_RALLY,DELETE_LIST_CLASSE,ADD_LIST_CLASSE} from "../actionsType";
export const defaultListRally=[];
export const listRally= (state = defaultListRally, action) => {
    switch (action.type) {
        case ADD_LIST_RALLY:
            state= action.payload.data;
            return state;
        case DELETE_LIST_RALLY:
            state=action.payload.data;
            return state;
        default:
            return state;
    }
};

const defaultResultByRally=[];
export const resultByRally= (state = defaultResultByRally, action) => {
    switch (action.type) {
        case ADD_RESULT_BY_RALLY:
            state.push(action.payload.data);
            return state;
        case DELETE_RESULT_BY_RALLY:
            state=action.payload.data;
            return state;
        default:
            return state;
    }
};

const defaultSpecialByRally=[];
export const specialByRally= (state = defaultSpecialByRally, action) => {
    switch (action.type) {
        case ADD_SPECIAL_BY_RALLY:
            state.push(action.payload.data);
            return state;
        case DELETE_SPECIAL_BY_RALLY:
            state=action.payload.data;
            return state;
        default:
            return state;
    }
};
const defaultSpecialByRallyrecap=[];
export const specialByRallyrecap= (state = defaultSpecialByRallyrecap, action) => {
    switch (action.type) {
        case ADD_SPECIAL_BY_RALLY_RECAP:
            state.push(action.payload.data);
            return state;
        case DELETE_SPECIAL_BY_RALLY_RECAP:
            state=action.payload.data;
            return state;
        default:
            return state;
    }
};

const defaultResultBySpecial=[];
export const resultBySpecial= (state = defaultResultBySpecial, action) => {
    switch (action.type) {
        case GET_RESULT_BY_SPECIAL:
            state.push(action.payload.data);
            return state;
        case DELETE_RESULT_BY_SPECIAL:
            state=action.payload.data
            return state;
        default:
            return state;
    }
};
export const defaultListClasse=[];
export const listClasse= (state = defaultListClasse, action) => {
    switch (action.type) {
        case ADD_LIST_CLASSE:
            state= action.payload.data;
            return state;
        case DELETE_LIST_CLASSE:
            state=action.payload.data;
            return state;
        default:
            return state;
    }
};