import React, { useState, useEffect } from "react";
import {StyleSheet, View, ScrollView,FlatList,TouchableOpacity,Text,ActivityIndicator} from "react-native";
import Rallye from "../_shared/rallye/Rallye";
import { useDispatch } from "react-redux";
import{getListRally} from "../../api/RallyApi";
import { useSelector } from "react-redux";
import { addListRallySelector,specialByRallySelector } from "../../redux/selectors";
const Home = ({route,navigation})=>{ 
    
    const dispatch = useDispatch();

    //const listSpecial= useSelector(specialByRallySelector);
    const listRally=useSelector(addListRallySelector);
    
    //console.log(listRally.length+" "+listSpecial.length);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(async() => {
        setIsLoading(true);   
         setIsLoading(await getListRally(dispatch));
        //console.log(isLoading)
    }, []);
    /*if(route.params!=null){
       
        let {isUpdate} = route.params;
        console.log("--"+isUpdate)
        if(isUpdate=="true"){
            async() => {
                console.log("-update-")
                setIsLoading(await getListRally(dispatch));
                listRally=useSelector(addListRallySelector);
            }

        }

        
    }*/
    //console.log("---"+isLoading)
    if (isLoading) {
        return(
            <View style={styles.buttonContainer}>
                <View style={styles.button}>
                {listRally.length==0  && <ActivityIndicator size="large" color="#E33813"/>}
                </View>
            </View>
        );
    }
    return(
        <ScrollView style={styles.homeContainer}>
            
           

            <View style={{marginBottom: 100}}>
                {
                listRally.map((data)=>(
                    <Rallye key={data.nomRallye+data.idRally} data={data} navigation={navigation}/>
                    ))
                }
            </View>
            
        </ScrollView>
    );
}
const styles = StyleSheet.create({
    homeContainer: {
        backgroundColor: "#fff"
    },
    buttonContainer:{
        alignItems:"center",
       
    },
    button:{
        padding:16,
        width:'auto',
    },
    buttonText:{
        color:"white",
        fontSize: 18,
        fontWeight:"bold"
    }
});
export default Home;