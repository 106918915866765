import React from "react";
import { StyleSheet, Text, View } from 'react-native';

const About = () => {
    return (
        <View style={styles.container}>
            <View style={styles.aboutContainer}>
                <Text style={styles.title}>A propos</Text>
                <Text style={styles.textDesc}>App Rallygasy en collaboration avec ITUniversity</Text>
            </View>
            <View style={styles.footerContainer}>
                <Text style={styles.textFooter}>Version : 0.3 </Text>
                <Text style={styles.textFooter}>Année : 2021 </Text>
            </View>
        </View>
    );
  }
const styles = StyleSheet.create({
    container:{
        
       
        flex:1,
        width:"auto",
        backgroundColor:"white"
    },
    title:{
        fontWeight:"bold",
        fontSize:25,
        textAlign:"center",
        width:"auto",
        marginTop:20,
       
    },
    textDesc:{
        fontSize:16,
        marginTop:30,
        textAlign:"center",
       width:300,
       
    },
    textFooter:{
        fontSize:16,
        marginTop:5,
        textAlign:"center",
       width:300,
       
    },aboutContainer:{
        flex:4,
        width:"auto",
        alignItems:"center"
    }
    ,footerContainer:{
        flex:1,
        width:"auto",
        alignItems:"center"
    }
});
export default About;