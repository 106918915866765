const data = {
    results : [
        {
            id : 1,
            title : "Mahajanga",
            subTitle : "Rallye du Boina",
            date : "05-11-2021",
            winner : "SEFO KELY-MINAH",
            flag : require("../../../../assets/images/mahajanga-flag.jpg")
        },
        {
            id : 2,
            title : "Antsirabe",
            subTitle : "Rallye Passion",
            
            date : "05-11-2020",
            winner : "ANJABOB-MIRINDRA",
            flag : require("../../../../assets/images/antsirabe-flag.jpg")
        },
        {
            id : 3,
            title : "Antananarivo",
            subTitle : "Tour de Tana",
            date : "05-11-2019",
            winner : "MANINTONA-B DANI",
            flag : require("../../../../assets/images/tana-flag.png")
        },
        {
            id : 4,
            title : "Toamasina",
            subTitle : "Rallye de l'Est",
            date : "05-11-2018",
            winner : "HERMAN-RAMAH",
            flag : require("../../../../assets/images/toamasina-flag.jpg")
        },
    ],
    images : [
        {
            id : 1,
            value : require("../../../../assets/images/antsirabe-flag.jpg")
        },
        {
            id : 2,
            value : require("../../../../assets/images/mahajanga-flag.jpg")
        },
        {
            id : 3,
            value : require("../../../../assets/images/tana-flag.png")
        },
        {
            id : 4,
            value : require("../../../../assets/images/toamasina-flag.jpg")
        },
    ],resultDetail : [
        {
            position:"1",
            carNumber:"97",
            crew:"SEFO KELY-MINAH",
            timeDiff:"06:17,5"
        },
        {
            position:"2",
            carNumber:"72",
            crew:"ANJABOB-MIRINDRA",
            timeDiff:"07:34,8"
        },
        {
            position:"3",
            carNumber:"31",
            crew:"MANINTONA-B DANI",
            timeDiff:"08:37,3"
        },
        {
            position:"4",
            carNumber:"20",
            crew:"HERMAN-RAMAH",
            timeDiff:"08:29,2"
        },
        {
            position:"5",
            carNumber:"11",
            crew:"FREDDY-JIMMY.R",
            timeDiff:"06:17,5"
        },
    ],
};

const getLocalImage = (id) => {
    for(let i=0; data.images.length; i++){
        if(data.images[i].id === id){
            return data.images[i].value;
        }
    }
}

export default {data, getLocalImage};