import React,{useState} from "react";
import {StyleSheet, View, Text,Image,TouchableOpacity} from "react-native";
import GlobalStyle from "../utils/GlobalStyle";

const Special = ({item,getResultMethode,getResultMethodeRecap,selectedSpecial,setSelectedSpecial,cumul, setCumul})=>{
    const executeMethode=()=>{
        getResultMethode(item.idSpeciale);
        setSelectedSpecial(item.idSpeciale);
        setCumul(false);
        console.log("cumul ato " + cumul);
    };

    const executeMethodeRecap = () => {
        getResultMethodeRecap(item.idSpeciale);
        setSelectedSpecial(item.idSpeciale);
        setCumul(true);
        console.log("cumul ato " + cumul);
    };
    return (
        <TouchableOpacity>
            <TouchableOpacity onPress={()=>{executeMethode()}}>
                <View style={[styles.stage,{backgroundColor: selectedSpecial === item.idSpeciale && cumul === false ? 'red' : 'black'}]}>
                    <Text style={styles.stageTitle}>ES{item.numero}</Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{executeMethodeRecap()}}>
                <View style={[styles.stagecumul,{backgroundColor: selectedSpecial === item.idSpeciale && cumul === true  ? 'red' : 'black'}]}>
                    <Text style={styles.stageTitle}>Cumul</Text>
                </View>
            </TouchableOpacity>
        </TouchableOpacity>
        
    );
}
const styles = StyleSheet.create({
    stage:{
        width:50,
        height:30,
        
        alignItems:"center",
        justifyContent:"center",
        marginRight:5
    },
    stagecumul:{
        width:50,
        height:30,
        marginTop:1,
        alignItems:"center",
        justifyContent:"center",
        marginRight:5
    },
    stageTitle:{
        color:"white"
    },
});
export default Special;