import { StatusBar } from 'expo-status-bar';
import React,{ useState, useEffect } from 'react';
import { Platform, StyleSheet, Text, View,ActivityIndicator } from 'react-native';
import Header from './src/components/_shared/header/Header';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from './src/components/home/Home';
import ResultInfo from './src/components/result/ResultInfo';
import { Icon } from 'react-native-elements';
import store from "./src/redux/store";
import { Provider } from "react-redux";
import { createDrawerNavigator } from '@react-navigation/drawer';
import PageNavigator from './src/components/navigation/PageNavigation';
import About from './src/components/About';
import LogoPage from './src/components/LogoPage';
import { Dimensions } from 'react-native';
const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();

const listItem=[
  {
    name:'Accueil',
    
  },
  {
    name:'A Propos',
  }
];

export default function App() {
  const [currentwidth, setCurrentWidth] = useState();
  useEffect(() => {
    let width = Dimensions.get('window').width;
    setCurrentWidth(width);
    const updateScreenDimensions = () => {
      let width = Dimensions.get('window').width;
      setCurrentWidth(Dimensions.get('window').width);
    };
    Dimensions.addEventListener('change', updateScreenDimensions);
  }, []);
  return (    
    <Provider store={store}>
      <View style={currentwidth > 900 ? styles.container : styles.containerMobile}>
        
        <NavigationContainer >
        <Drawer.Navigator 
            initialRouteName="Accueil"
            screenOptions={{
              itemStyle: { marginVertical: 10 },
              drawerLabelStyle:{
                color:"black",
              },
              drawerActiveBackgroundColor:'#FCE0DA',
              drawerLabelStyle:{color:"#2E0A02"}
            }}
            drawerContent={(props) => <LogoPage {...props} />
            }
            
          >
            <Drawer.Screen
              name="Accueil"
              options={{ drawerLabel: 'Accueil' ,
                headerShown:true,
                header: () => {
                  return (
                    <Header />
                  );
                }
              }}
              component={PageNavigator}
            />
            <Drawer.Screen
              name="A Propos"
              options={{ drawerLabel: 'A Propos' ,
                headerShown:true,
                header: () => {
                  return (
                    <Header />
                  );
                }
              }}
              component={About}
            />
          </Drawer.Navigator>
          
        </NavigationContainer>
        
      </View>
      <StatusBar style="auto" />
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
        paddingLeft: '35%',
        paddingRight: '35%', 
  },
  containerMobile: {
    flex: 1,
    backgroundColor: '#fff',
  },
  
titleIcon : {
    height: 25,
    width: 25,
   
},
});
