import { StyleSheet} from "react-native";
const defaultColor = "#aaa";
const activeColor="#191970";
const styles = StyleSheet.create({
    headerStyle: {
        height: 80,
        backgroundColor: "#212a47",
        color : "#fff"
    }
});

export default {
    defaultColor,
    styles,
    activeColor
}